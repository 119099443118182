import { http } from './config'
import authHeader from './auth-header';

export default {

    lista: () => {
        return http.get('resposta_carne' , { headers: authHeader() })

    },

   

}
