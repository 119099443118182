<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section">





<div class="card">

  

  <div class="card-body">
    <h4 class="card-title"></h4>
    <base-material-card
      icon="mdi-ticket"
      title="Carnê Digital"
      class="px-5 py-3"
    >




      <v-data-table  :headers="fields" :items="aceites" item-key="codigo" class="elevation-0" :loading="loading">
        
         <template v-slot:item.resposta="{ item }">
            <v-chip
              :color="getBadge(item.resposta)"
              dark
            >
              {{item.resposta}}                    
            </v-chip>
        </template>


        <template v-slot:item.data_hora="{ item }">
           <span>{{currentDateTime(item.data_hora)}}</span>
        </template>

      </v-data-table>

    </base-material-card>
      <br/>
     <v-row
      > 
      

        <download-excel :data = "this.json_data" name = "cupons.xls" :fields="json_fields"
> 
          <v-btn  color="blue text-white"  >Download excel
            <v-icon right dark>
              mdi-file-table
            </v-icon>

          </v-btn>

        </download-excel>

    </v-row>

  </div>
</div>
   
  </v-container>
</template>
<script>
  import Carnes from '../../../services/carnes'
  import moment from 'moment'




  export default {

     created() {
          if(this.$store.state.auth.status.loggedIn == false){
            this.$router.push('/')
          }
      },

    data: () => ({
    
    collapseDuration: 0,
    show: true,
    loading: false,
     json_fields: {
      "Contrato": "contrato",
      "Resposta": "resposta",
      "Plataforma": "plataforma",
      "Data e Hora": {
        field: "data_hora",
        callback: (value) => {
          return  moment(value).format('DD/MM/YYYY HH:MM:SS')
        },
      }
     },
     json_data: [],
     select_status: true,
       fields:[
        { text: 'Contrato', sorter: true, filter: false, value:'contrato', class: 'title'},
        { text: 'Resposta' , sorter: false, filter: false, value:'resposta', class:'title'},
        { text: 'Plataforma', sorter: false, filter: false, value:'plataforma', class:'title'},
        { text: 'Data e Hora', sorter: false, filter: false, value:'data_hora', class:'title'},
      ],
      aceites: []

    }),

    mounted () {
      this.listar()
    },

    methods: {

  

      listar () {
          this.loading = true

        Carnes.lista().then(response => {
            this.aceites = response.data
            this.json_data = response.data
        }).catch(e => {
            if(e.response.status === 401){
            //this.$store.dispatch('auth/logout');
            //this.$router.push('/pages/login');
            }

        }).finally(() => {
             this.loading = false

        })
      },
     
   
     
      getBadge (status) {
      switch (status) {
        case 'SIM': return 'green lighten-3'
        case 'NÃO': return 'red lighten-2'
        default: 'danger'
      }
      },

   
    currentDateTime(date) {
      return moment(date).format('DD/MM/YYYY HH:MM:SS')
    },

    },

    getSituacaoStatus (status) {
        switch (status) {
          case 'SIM': return 'success'
          case 'NÃO': return 'danger'
          default: 'danger'
        }
      },


    

    
  }

</script>
